<template>
  <div>
    <div v-if="isLoading">
      <div v-if="!isMobile()">
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              height="200"
              type="card"
            ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-5">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-center mt-3 mt-10 pt-10">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-1 pa-1 round-16"
            width="200"
            height="80"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-10 pt-10">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center pt-5">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- DESKTOP -->
      <div class="headline black--text font-weight-bold d-flex" v-if="!isMobile()">
        <div>
          <p class="mb-1 mt-0">Master Campaign Report</p>
          <p class="mb-2 subtitle-2">{{ itemData[0].name}}</p>
        </div>
        <p class="ml-auto mt-4 subtitle-2">{{ itemData[0].date }}</p>
        <!-- <v-btn color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn> -->
      </div>
      <!-- MOBILE -->
      <div class="black--text pt-0" v-else>
        <!-- Report Campaign -->
        <p class="mb-0 text-20 font-weight-bold text-center">{{ itemData[0].name}}</p>
        <p class="mb-5 text-10 text-center">{{ itemData[0].date }}</p>
        <!-- <v-btn block color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn> -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <v-card color="" class="elevation-1 mt-2 pa-5 pt-2 round-16">
            <!-- DESKTOP -->
            <div class="d-flex mb-3 mt-2" v-if="!isMobile()">
              <div class="headline black--text font-weight-bold">
                <p class="mb-0">Influencers</p>
              </div>
              <v-btn rounded color="primary" class="text-capitalize ml-auto" @click.prevent="openDialog('insert', '')"><i class="fas fa-plus mr-2"></i> Tambah Influencer</v-btn>
            </div>
            <!-- MOBILE -->
            <div class="d-flex mb-3" v-else>
              <div>
                  <p class="mb-0 text-20 black--text font-weight-bold">Campaigns</p>
                </div>
              <v-btn rounded small color="primary" class="text-capitalize ml-auto" @click.prevent="openDialog('insert', '')"><i class="fas fa-plus mr-2"></i>Influencer</v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="itemData"
              item-key="id"
              :loading="loadingTable"
              loading-text="Memuat data . . ."
              class="elevation-0 text-capitalize"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:[`item.username`]="{ item }">
                <div v-if="item.username" class="text-12">
                    {{ item.username }}
                </div>
              </template>
              <template v-slot:[`item.content`]="{ item }">
                <div v-if="item.content" class="text-12">
                    {{ item.content }}
                </div>
              </template>
              <template v-slot:[`item.follower`]="{ item }">
                <div v-if="item.follower > 0 " class="text-12">
                    {{ item.follower | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.reach`]="{ item }">
                <div v-if="item.reach > 0 " class="text-12">
                    {{ item.reach | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.impressions`]="{ item }">
                <div v-if="item.impressions > 0 " class="text-12">
                    {{ item.impressions | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.clicks`]="{ item }">
                <div v-if="item.clicks > 0 " class="text-12">
                    {{ item.clicks | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.views`]="{ item }">
                <div v-if="item.views > 0 " class="text-12">
                    {{ item.views | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.likes`]="{ item }">
                <div v-if="item.likes > 0 " class="text-12">
                    {{ item.likes | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.comments`]="{ item }">
                <div v-if="item.comments > 0 " class="text-12">
                    {{ item.comments | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.retweet`]="{ item }">
                <div v-if="item.retweet > 0 " class="text-12">
                    {{ item.retweet | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.expands`]="{ item }">
                <div v-if="item.expands > 0 " class="text-12">
                    {{ item.expands | numberFormat }}
                </div>
              </template>
              <template v-slot:[`item.region`]="{ item }">
                <div v-if="item.region" class="text-12">
                    {{ item.region }}
                </div>
              </template>
              <template v-slot:[`item.upload_date`]="{ item }">
                  <div v-if="item.upload_date != null" class="text-12">
                      {{ item.upload_date | dateLimit }}
                  </div>
              </template>
              <template v-slot:[`item.link`]="{ item }">
                <v-btn v-if="item.link" rounded small color="primary" class="text-capitalize" @click="openLink(item.link)">Buka</v-btn>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  color="warning"
                  class="text-capitalize"
                  x-small
                  fab
                  @click.prevent="openDialog('edit', item.id)"
                ><i class="fas fa-pen"></i>
                </v-btn>
                <v-btn
                  color="error"
                  class="text-capitalize"
                  x-small
                  fab
                  @click.prevent="openDialog('delete', item.id)"
                >
                  <i class="fas fa-trash"></i>
                </v-btn>
              </template>
            </v-data-table>
            <div class="text-center mt-2 pt-0">
              <v-pagination
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </div>
            <div class="text-center pb-2">
            </div>
          </v-card>
        </div>
      </div>

      <!-- DIALOG INSERT DATA -->
      <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span>{{ dialogItem.name }}</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pa-0">
              <!-- <v-row class="pb-0 mb-2 pt-0 mt-0">
                <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                  <v-autocomplete
                    v-model="contentMaster"
                    class="mb-4 pb-0"
                    :items="contents"
                    item-text="label"
                    item-value="value"
                    label="Konten Master"
                    @change="changeMaster"
                    filled
                    dense
                    hide-details="true"
                  ></v-autocomplete>
                </div>
                <div class="col-md-12 pb-0 mb-0 pt-0 mt-0">
                  <div class="row">
                    <div class="col-md-3">
                      <v-switch class="pt-0 mt-0 mb-2"
                        v-model="viewsMaster"
                          label="Views"
                          hide-details="true"
                          @change="changeMaster"
                      ></v-switch>
                    </div>
                    <div class="col-md-3">
                      <v-switch class="pt-0 mt-0 mb-2 text-10"
                        v-model="impressionsMaster"
                          label="Impressions"
                          hide-details="true"
                          @change="changeMaster"
                      ></v-switch>
                    </div>
                    <div class="col-md-3">
                      <v-btn
                        color="error"
                        class="text-capitalize"
                        small
                        rounded
                        @click.prevent="resetMaster()"
                      >
                        <i class="fas fa-times-circle mr-1"></i> Reset Master
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-row> -->
              <v-row class="pb-0 mb-0 pt-0 mt-0" v-if="dialogItem.name == 'Tambah Influencer'">
                <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2"
                    v-model="switchInflu"
                      label="Baru"
                      hide-details="true"
                  ></v-switch>
                  <v-autocomplete
                    v-if="!switchInflu"
                    v-model="influName"
                    class="mb-2 pb-0"
                    :items="influencers"
                    item-text="label"
                    item-value="value"
                    label="Username"
                    outlined
                    hide-details="true"
                    dense
                  ></v-autocomplete>
                  <div v-else>
                    <v-text-field
                      v-model="influNameManual"
                      label="Username Manual"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                    <v-text-field
                      class="mb-2"
                      v-model="followerVal"
                      label="Followers"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-md-4 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2"
                    v-model="switchContent"
                    @change="changeSwitch('content')"
                      label="Otomatis"
                      hide-details="true"
                  ></v-switch>
                  <v-autocomplete
                    v-if="switchContent"
                    v-model="content"
                    class="mb-2 pb-0"
                    :items="contents"
                    item-text="label"
                    item-value="value"
                    label="Konten"
                    @change="changeMaster('content')"
                    outlined
                    dense
                    hide-details="true"
                  ></v-autocomplete>
                  <div v-else>
                    <v-text-field
                      v-model="content"
                      label="Konten"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </div>
                  <!-- <v-autocomplete
                    v-if="!switchContent"
                    v-model="content"
                    class="mb-4 pb-0"
                    :items="contents"
                    item-text="label"
                    item-value="value"
                    label="Konten"
                    outlined
                    dense
                    hide-details="true"
                  ></v-autocomplete>
                  <div v-else>
                    <v-text-field
                      v-model="content"
                      label="Konten Manual"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </div> -->
                </div>
                <div class="col-md-4 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2"
                    v-model="switchUpload"
                    @change="changeSwitch('upload')"
                      label="Otomatis"
                      hide-details="true"
                  ></v-switch>
                  <v-menu
                      v-model="menu"
                      class="mb-0 pb-0"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="upload"
                          label="Tgl Upload"
                          outlined
                          dense
                          readonly
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="upload"
                        @input="changeMaster(); menu = false"
                      ></v-date-picker>
                    </v-menu>
                </div>
              </v-row>
              <v-row class="pb-0 mb-0 pt-0 mt-0" v-else>
                <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                  <v-autocomplete
                    v-model="influName"
                    class="mb-4 pb-0"
                    :items="influencers"
                    item-text="label"
                    item-value="value"
                    label="Username"
                    outlined
                    hide-details="true"
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                  <v-autocomplete
                    v-model="content"
                    class="pb-0"
                    :items="contents"
                    item-text="label"
                    item-value="value"
                    label="Konten"
                    outlined
                    dense
                    hide-details="true"
                  ></v-autocomplete>
                </div>
                <div class="col-md-4 pb-0 mb-2 pt-0 mt-0">
                  <v-menu
                      v-model="menu"
                      class="mb-0 pb-0"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="upload"
                          label="Tgl Upload"
                          outlined
                          dense
                          readonly
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="upload"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                </div>
              </v-row>
              <v-row class="pb-0 mb-0 pt-0 mt-0" v-if="!loadingImage">
                <div class="col-md-4 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="impressionsMaster"
                      label="Auto Impressions"
                      hide-details="true"
                      @change="copyReach('impressions')"
                  ></v-switch>
                </div>
                <div class="col-md-4 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="viewsMaster"
                      label="Auto Views"
                      hide-details="true"
                      @change="copyReach('views')"
                  ></v-switch>
                </div>
                <div class="col-md-4 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mr-4 pb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="advanced"
                      label="Semua Kolom"
                      hide-details="false"
                  ></v-switch>
                </div>
              </v-row>
              <v-row class="pb-0 mb-0 pt-0 mt-0" v-if="!loadingImage">
                <div v-if="advanced || contentType === 'story' || contentType === 'carousel' || contentType === 'reels'" class="col-md-2 col-6 pb-0 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 mb-2" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="reachMaster"
                      label=""
                      hide-details="true"
                      disabled
                  ></v-switch> -->
                  <v-text-field
                    class="pb-2"
                    v-model="reachVal"
                    label="Reach"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story' || contentType === 'carousel' || contentType === 'reels' || contentType === 'twitter'" class="col-md-2 col-6 pb-0 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 mb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="impressionsMaster"
                      label="Otomatis"
                      hide-details="true"
                      @change="copyReach('impressions')"
                  ></v-switch> -->
                  <v-text-field
                    v-model="impresVal"
                    label="Impressions"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story' || contentType === 'reels' || contentType === 'twitter' || contentType === 'tiktok'" class="col-md-2 col-6 pb-0 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 mb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="viewsMaster"
                      label="Otomatis"
                      hide-details="true"
                      @change="copyReach('views')"
                  ></v-switch> -->
                  <v-text-field
                    v-model="viewsVal"
                    class="pb-2"
                    label="Views"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story'" class="col-md-2 col-6 pb-0 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 mb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="reachMaster"
                      label=""
                      hide-details="true"
                      disabled
                  ></v-switch> -->
                  <v-text-field
                    v-model="clicksVal"
                    label="Clicks"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story' || contentType === 'carousel' || contentType === 'reels' || contentType === 'twitter' || contentType === 'tiktok'" class="col-md-2 col-6 pb-2 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 pb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="reachMaster"
                      label=""
                      hide-details="true"
                      disabled
                  ></v-switch> -->
                  <v-text-field
                    v-model="likesVal"
                    label="Likes"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'carousel' || contentType === 'reels' || contentType === 'twitter' || contentType === 'tiktok'" class="col-md-2 col-6 pb-0 mb-0 pt-0 mt-0">
                  <!-- <v-switch class="pt-0 mt-0 pb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="reachMaster"
                      label=""
                      hide-details="true"
                      disabled
                  ></v-switch> -->
                  <v-text-field
                    v-model="commentsVal"
                    label="Comments"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story' || contentType === 'carousel' || contentType === 'reels' || contentType === 'tiktok'" class="col-md-2 col-6 pb-2 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="shareVal"
                    label="Share"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'carousel' || contentType === 'reels' || contentType === 'tiktok'" class="col-md-2 col-6 pb-2 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="savedVal"
                    label="Saved"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story'" class="col-md-2 col-6 pb-2 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="replyVal"
                    label="Reply"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'twitter'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="retweetVal"
                    label="Retweet"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'twitter'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="expandsVal"
                    label="Expands"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'tiktok'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="avdVal"
                    label="AWT / AVD"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'tiktok'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="wfvVal"
                    label="WFV"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'tiktok'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="playVal"
                    label="Play"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="advanced || contentType === 'story'" class="col-md-2 col-6 pb-3 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="tapVal"
                    label="Taps"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div v-if="contentType !== ''" class="col-md-2 col-6 pb-2 mb-0 pt-0 mt-0">
                  <v-text-field
                    v-model="accountEngagedVal"
                    label="Account Engaged"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
              </v-row>
              <v-row class="pb-0 mb-0 pt-0 mt-0" v-else>
                <div class="col-md-12">
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </div>
              </v-row>
              <v-row class="pb-0 mb-2 mt-0" v-if="contentType !== ''" >
                <div class="col-md-6 col-6 pb-0 mb-2 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="switchRegion"
                      label="Baru"
                      hide-details="false"
                  ></v-switch>
                  <v-autocomplete
                    v-if="!switchRegion"
                    v-model="region"
                    class="mb-2 pb-0"
                    :items="regions"
                    item-text="label"
                    item-value="value"
                    label="Region"
                    outlined
                    hide-details="true"
                    dense
                  ></v-autocomplete>
                  <v-text-field v-else
                    v-model="region"
                    label="Region"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
                <div class="col-md-6 col-6 pb-0 mb-0 pt-0 mt-0">
                  <v-switch class="pt-0 mt-0 mb-2" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="reachMaster"
                      label=""
                      hide-details="false"
                      disabled
                  ></v-switch>
                  <v-text-field
                    v-model="link"
                    label="Link Konten"
                    outlined
                    dense
                    hide-details="true"
                  ></v-text-field>
                </div>
              </v-row>
              <!-- <v-row class="pb-0 mb-2 pt-0 mt-0">
                <div class="col-md-6 pb-0 mb-0 pt-0 mt-2"> -->
                  <!-- <v-file-input
                    label="Upload"
                    hide-details="true"
                    @change="scanningImage"
                    outlined
                    dense
                  ></v-file-input> -->
                  <!-- <p class="mb-2 text-16">Upload Insight</p> -->
                  <!-- <input v-if="dialogItem.name == 'Tambah Influencer'" type="file" @change="scanningImage" id="imageLoader"/>

                </div>
                <div class="col-md-6 pb-0 mb-0 pt-0 mt-2">
                  <v-switch class="pt-0 mt-0 mb-2" v-if="dialogItem.name == 'Tambah Influencer'"
                    v-model="isScanning"
                      label="Scan"
                      hide-details="true"
                  ></v-switch>
                </div>
              </v-row> -->
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!-- <v-switch class="pt-0 mt-0 mr-4 pb-2 text-10" v-if="dialogItem.name == 'Tambah Influencer'"
              v-model="advanced"
                label="Advanced"
                hide-details="false"
            ></v-switch> -->
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize"
              @click="dialog = false"
            >
              Batal
            </v-btn>
            <v-btn
              v-if="dialogItem.name === 'Tambah Influencer'"
              color="primary"
              class="text-capitalize"
              @click="submit"
            >
              Simpan
            </v-btn>
            <v-btn
              v-if="dialogItem.name === 'Ubah Influencer'"
              color="primary"
              class="text-capitalize"
              @click="submitEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG LOADING -->
      <v-dialog
        v-model="loadingSave"
        persistent
        max-width="350"
      >
        <v-card :color="bgDialog">
          <div class="d-flex justify-center pa-10">
            <v-progress-circular
              v-if="!success"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <!-- <div v-else>
              <v-alert type="success">
                {{ dialogMessage }} <button style="float:right" type="button" v-on:click="loadingSave = false" class="close right ml-5" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </v-alert>
            </div> -->
          </div>
        </v-card>
      </v-dialog>

      <!-- DIALOG DELETE -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Anda yakin hapus influencer ini ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" class="text-capitalize" @click="dialogDelete = false">Batal</v-btn>
            <v-btn color="primary" class="text-capitalize" @click="deleteItem">Hapus</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

  <script>
  import { defineComponent } from '@vue/composition-api'

  import axios from 'axios'
  import { createWorker, PSM, OEM } from 'tesseract.js';

  import Tesseract from 'tesseract.js'

  export default defineComponent({
    setup() {
      return{
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        itemId:'',
        influencers:[],
        influencerName : [],
        contents:[],
        itemData:[],
        isLoading:true,
        alert:false,
        loadingTable:true,
        loadingSave:false,
        loadingInfluencer:true,
        loadingContent:true,
        success:false,
        dialog:false,
        dialogItem : {
          'name':'',
          'id':''
        },
        dialogDelete:false,
        upload: '',
        menu:false,
        influName:'',
        influNameManual:'',
        reachVal:'',
        impresVal:'',
        viewsVal:'',
        clicksVal:'',
        likesVal:'',
        commentsVal:'',
        content:'',
        region:'',
        followerVal:'',
        switchInflu:false,
        switchContent:true,
        switchUpload:true,
        loadingImage:false,
        contentMaster:'',
        uploadMaster:'',
        viewsMaster:false,
        impressionsMaster:false,
        reachMaster:false,
        shareVal:'',
        savedVal:'',
        replyVal:'',
        accountEngagedVal:'',
        avdVal:'',
        wfvVal:'',
        playVal:'',
        tapVal:'',
        retweetVal:'',
        expandsVal:'',
        link:'',
        temPhoto:'',
        isScanning:true,
        advanced: false,
        twitter: false,
        switchRegion: false,
        regions:[],
        loadingRegion:true,
        contentType: ''
      }
    },
    mounted(){
      this.getInfluencers();
      this.getContents();
      this.getRegions();
      this.item = this.$route.query.item
      this.itemId = this.decryptData(this.item)
      this.loadCampaign();
      this.loadMaster();
    },
    computed: {
      headers() {
        return [
          {text: 'Aksi', align: 'center', sortable: false, value: 'action', width: '100px'},
          {text: 'No.', value: 'num', sortable: false, align: 'center', width: '10'},
          {text: 'Username', value: 'username', sortable: false, align: 'center'},
          {text: 'Followers', value: 'follower', sortable: false, align: 'left'},
          {text: 'Konten', value: 'content', sortable: false, align: 'center', width: '10'},
          {text: 'Upload', value: 'upload_date', sortable: false, align: 'center'},
          {text: 'Reach', value: 'reach', sortable: false, align: 'left'},
          {text: 'Impressions', value: 'impressions', sortable: false, align: 'left'},
          {text: 'Views', value: 'views', sortable: false, align: 'left'},
          {text: 'Clicks', value: 'clicks', sortable: false, align: 'left'},
          {text: 'Likes', value: 'likes', sortable: false, align: 'left'},
          {text: 'Comments', value: 'comments', sortable: false, align: 'left'},
          {text: 'Region', value: 'region', sortable: false, align: 'left'},
          {text: 'Share', value: 'shares', sortable: false, align: 'left'},
          {text: 'Saved', value: 'saved', sortable: false, align: 'left'},
          {text: 'Reply', value: 'reply', sortable: false, align: 'left'},
          {text: 'Account Engaged', value: 'account_engaged', sortable: false, align: 'left'},
          {text: 'Play', value: 'play', sortable: false, align: 'left'},
          {text: 'Taps', value: 'taps', sortable: false, align: 'left'},
          {text: 'AVD', value: 'avd', sortable: false, align: 'left'},
          {text: 'WFV', value: 'wfv', sortable: false, align: 'left'},
          {text: 'URL', value: 'link', sortable: false, align: 'center'},
          {text: 'Retweet', value: 'retweet', sortable: false, align: 'center'},
          {text: 'Expands', value: 'expands', sortable: false, align: 'center'}
        ]
      },
      filteredFormat(){
        if(this.selectType === 'link-bio'){
          return this.ContentFormat.filter(item => item.value === 'link-bio')
        } else {
          return this.ContentFormat.filter(item => item.value != 'link-bio')
        }
      },
      bgDialog(){
        return this.success ? 'transparent' : '#fff';
      }
    },
    methods:{
      decryptData(val) {
        let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
        let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
        return decryptData;
      },
      getInfluencers(){
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'influencers', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let client = response.data.data
              for(let a = 0; a < client.length; a++){
                let arrayClient = {
                  'label': client[a].instagram+' ('+this.numberFormat(client[a].follower_ig)+')',
                  'value': client[a].id
                }
                let arrayClientName = {
                  'label': client[a].instagram,
                  'value': client[a].id
                }
                this.influencerName.push(arrayClientName)
                this.influencers.push(arrayClient)
              }
              setTimeout(() => {
                this.isLoading = false
                this.loadingInfluencer = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      getContents(){
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/getContents', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let content = response.data.data
              for(let a = 0; a < content.length; a++){
                let arraycontent = {
                  'label': content[a].content,
                  'value': content[a].content
                }
                this.contents.push(arraycontent)
              }
              setTimeout(() => {
                this.isLoading = false
                this.loadingContent = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      getRegions(){
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/getRegions', {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let region = response.data.data
              for(let a = 0; a < region.length; a++){
                let arrayregion = {
                  'label': region[a].region,
                  'value': region[a].region
                }
                this.regions.push(arrayregion)
              }
              setTimeout(() => {
                this.isLoading = false
                this.loadingRegion = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      loadCampaign(){
        this.isLoading = true;
        this.loadingTable = true
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/getCampaignDetail/'+this.itemId, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              this.itemData = response.data.data
              for(let a = 0; a < this.itemData.length; a++){
                this.itemData[a].date_added = new Date(this.itemData[a].date_added)
                // this.itemData[a].upload_date = new Date(this.itemData[a].upload_date.replace(/-/g, "/"))
                this.itemData[a].campaign_date = new Date(this.itemData[a].campaign_date)
                // this.itemData[a].upload_date = this.itemData[a].upload_date.slice(0,10)
                this.itemData[a].num = a+1
                this.impressions += this.itemData[a].impressions ? parseInt(this.itemData[a].impressions) : 0
                this.reach += this.itemData[a].reach ? parseInt(this.itemData[a].reach) : 0
                this.views += this.itemData[a].views ? parseInt(this.itemData[a].views) : 0
                this.likes += this.itemData[a].likes ? parseInt(this.itemData[a].likes) : 0
                this.comments += this.itemData[a].comments ? parseInt(this.itemData[a].comments) : 0
                this.clicks += this.itemData[a].clicks ? parseInt(this.itemData[a].clicks) : 0
                this.followers += this.itemData[a].follower ? parseInt(this.itemData[a].follower) : 0
                // this.share += this.itemData[a].share ? parseInt(this.itemData[a].share) : 0
                // this.saved += this.itemData[a].saved ? parseInt(this.itemData[a].saved) : 0
              }
              this.itemData[0].date = this.checkCamp(new Date(this.itemData[0].start_date), new Date(this.itemData[0].end_date))
              if(this.isMobile()){
                this.itemsPerPage = this.itemData.length
              }
              this.cpr = parseInt(this.itemData[0].budget) / this.reach
              // this.level = [ ...new Set(this.level) ]
              // console.log(this.itemData)
              setTimeout(() => {
                this.isLoading = false
                this.loadingTable = false
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      loadCampaignItem(id){
        // this.isLoading = true;
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/getCampaignDetailItem/'+id, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              let itemDataDetail = response.data.data
              // campaign_id: this.itemId,
              // influencer_id: this.influName,
              if(itemDataDetail.influencer_id == null || itemDataDetail.influencer_id == ''){
                this.influName = itemDataDetail.username
              } else{
                this.influName = itemDataDetail.influencer_id
              }

              this.content = itemDataDetail.content
              this.upload = this.dateLimit(itemDataDetail.upload_date)
              this.reachVal = itemDataDetail.reach
              this.impresVal = itemDataDetail.impressions
              this.viewsVal = itemDataDetail.views
              this.clicksVal = itemDataDetail.clicks
              this.likesVal = itemDataDetail.likes
              this.commentsVal = itemDataDetail.comments
              this.region = itemDataDetail.region
              this.shareVal = itemDataDetail.shares
              this.savedVal = itemDataDetail.saved
              this.replyVal = itemDataDetail.reply
              this.accountEngagedVal = itemDataDetail.account_engaged
              this.avdVal = itemDataDetail.avd
              this.wfvVal = itemDataDetail.wfv
              this.playVal = itemDataDetail.play
              this.tapVal = itemDataDetail.taps
              this.link = itemDataDetail.link
              this.retweetVal = itemDataDetail.retweet
              this.expandsVal = itemDataDetail.expands
              // }
              setTimeout(() => {
                // this.isLoading = false
              }, 200);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      loadMaster(){
        if(localStorage.getItem('master_report')){
          let master_session = JSON.parse(localStorage.getItem('master_report'));
          this.contentMaster = master_session.content;
          this.uploadMaster = master_session.upload;
          // this.viewsMaster = master_session.views;
          this.impressionsMaster = master_session.impressions;
        }
      },
      changeMaster(val=''){
        if(localStorage.getItem('master_report')){
          localStorage.removeItem('master_report');
        }
        let dataMaster = {
          'content' : this.content,
          'upload' : this.upload,
          'impressions' : this.impressionsMaster,
          'views' : this.viewsMaster,
        }
        localStorage.setItem('master_report', JSON.stringify(dataMaster));
        if(val === 'content'){
          if (/twitter/i.test(this.content)) {
            this.contentType = 'twitter'
          } else if (/story/i.test(this.content)) {
            this.contentType = 'story'
          } else if (/carousel/i.test(this.content)) {
            this.contentType = 'carousel'
          } else if (/reels/i.test(this.content)) {
            this.contentType = 'reels'
          } else if (/tiktok/i.test(this.content)) {
            this.contentType = 'tiktok'
          } else if (/photo/i.test(this.content)) {
            this.contentType = 'carousel'
          }
        }
      },
      resetMaster(){
        localStorage.removeItem('master_report');
        this.contentMaster = '';
        this.uploadMaster = '';
        this.viewsMaster = false;
        this.impressionsMaster = false;
      },
      changeSwitch(val){
        if(val == 'content'){
          if(this.switchContent){
            this.content = this.contentMaster
          } else {
            this.content = ''
          }
        } else if(val == 'upload'){
          if(this.switchUpload){
            this.upload = this.uploadMaster
          } else {
            this.upload = ''
          }
        }
      },
      copyReach(val){
        if(val == 'impressions'){
          if(this.impressionsMaster){
            this.impresVal = this.reachVal
          } else {
            this.impresVal = ''
          }
        } else {
          if(this.viewsMaster){
            this.viewsVal = this.reachVal
          } else {
            this.viewsVal = ''
          }
        }
        this.changeMaster()
      },
      scanningImage(e){
        var reader, files = e.target.files;
        var reader = new FileReader();
        let reachStatus = 0
        let reachScan = 0
        let impresScan = 0
        let clickScan = 0
        this.temPhoto = files[0];

        if(this.isScanning){
          this.loadingImage = true
          reader.onload = (e) => {
            var img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
              Tesseract.recognize(img)
              // .progress(function  (p) { console.log('progress', p)    })
              .then((result) => {
                // console.log(result.data.text)
                let arrData = result.data.lines
                for(let i in arrData){
                  if(reachStatus == 0){
                    if(arrData[i].text.includes('reached')){
                      if(arrData[i].words.length > 2){
                        reachScan = arrData[i].words[2].text.replace(/[,.]/g,'')
                        reachStatus = 1
                      }
                    }
                    if(arrData[i].text.includes('Akun yang dijangkau')){
                      if(arrData[i].words.length > 3){
                        reachScan = arrData[i].words[3].text.replace(/[,.]/g,'')
                        reachStatus = 1
                      }
                    }
                  }
                  if(arrData[i].text.includes('Impressions') || arrData[i].text.includes('Impresi')){
                    if(arrData[i].words.length > 1){
                      impresScan = arrData[i].words[1].text.replace(/[,.]/g,'')
                    }
                  }
                  if(arrData[i].text.includes('Clicks') || arrData[i].text.includes('Klik Tautan')){
                    if(arrData[i].words.length > 2){
                      clickScan = arrData[i].words[2].text.replace(/[,.]/g,'')
                    }
                  }

                  this.reachVal = reachScan
                  this.impresVal = impresScan
                  this.clicksVal = clickScan

                  if(this.viewsMaster){
                    this.viewsVal = reachScan
                  } else {
                    if(arrData[i].text.includes('dh =')){
                      if(arrData[i].words.length > 2){
                        this.viewsVal = arrData[i].words[2].text.replace(/[,.]/g,'')
                      }
                    }
                  }
                }
                this.loadingImage = false
              })
            }
            img.src = event.target.result;
          };
          reader.readAsDataURL(files[0]);
        }
      },
      async submit(){
        this.dialog = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        if(this.temPhoto != ''){
          // CHANGE IMAGE NAME
          let splitName = this.temPhoto.name.split(".")
          let extName = splitName[1]
          var blob = this.temPhoto.slice(0, this.temPhoto.size, this.temPhoto.type);
          let influ
          if(this.switchInflu){
            influ = this.influNameManual
          } else {
            for(let i in this.influencerName){
              if(this.influencerName[i].value == this.influName){
                influ = this.influencerName[i].label
              }
            }
          }
          let newName = this.itemId+'-'+influ
          let newFile = new File([blob], newName+'.jpg', {type: this.temPhoto.type});
          // console.log(newFile)
          let formData = new FormData();
          formData.append('file', newFile, newFile.name);
          try {
            const response = await fetch(process.env.VUE_APP_API_ENDPOINT + 'campaign/upload', {
              method: 'POST',
              body: formData,
            }).then(response => response.json())
            .then(data => {
              // this.photo = data.filename;
              // console.log(data)
            }).catch((error) => {
              console.error('Error:', error);
            });
          } catch (err) {
            console.log(err);
          }
        }

        // let formData = new FormData();
        // formData.append('file', this.temPhoto);
        // formData.append('campaign_id', this.itemId)
        // formData.append('influencer_id', this.influName)
        // formData.append('content', this.content)
        // formData.append('upload_date', this.upload)
        // formData.append('reach', this.reachVal)
        // formData.append('impressions', this.impresVal)
        // formData.append('views', this.viewsVal)
        // formData.append('clicks', this.clicksVal)
        // formData.append('likes', this.likesVal)
        // formData.append('comments', this.commentsVal)
        // formData.append('region', this.region)
        // formData.append('name',this.influNameManual)
        // formData.append('follower',this.followerVal)
        // formData.append('shares',this.shareVal)
        // formData.append('saved', this.savedVal)
        // formData.append('reply', this.replyVal)
        // formData.append('avd',this.avdVal)
        // formData.append('wfv',this.wfvVal)
        // formData.append('plays',this.playVal)
        // formData.append('taps',this.tapVal)
        // formData.append('link',this.link)
        // try {
        //   const response = await fetch(process.env.VUE_APP_API_ENDPOINT + 'campaign/campaignDetailNew', {
        //     method: 'POST',
        //     body: formData,
        //     headers: {
        //       'Authorization': 'Bearer '+ token
        //     }
        //   }).then(response => response.json())
        //   .then(data => {
        //     // this.photo = data.filename;
        //     // console.log(data)
        //     if(data.statusCode === 200){
        //       setTimeout(() => {
        //         this.dialogMessage = 'Berhasil Tersimpan'
        //         this.success = true
        //         this.loadCampaign()
        //         this.getContents()
        //         this.getInfluencers()
        //         this.upload = ''
        //       }, 500);
        //     }
        //   }).catch((error) => {
        //     console.error('Error:', error);
        //   });
        // } catch (err) {
        //   console.log(err);
        // }
        axios.post(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/campaignDetail', {
            campaign_id: this.itemId,
            influencer_id: this.influName,
            content: this.content,
            upload_date: this.upload,
            reach: this.reachVal,
            impressions: this.impresVal,
            views: this.viewsVal,
            clicks: this.clicksVal,
            likes: this.likesVal,
            comments: this.commentsVal,
            region: this.region,
            name:this.influNameManual,
            follower:this.followerVal,
            shares:this.shareVal,
            saved : this.savedVal,
            reply : this.replyVal,
            account_engaged : this.accountEngagedVal,
            avd:this.avdVal,
            wfv:this.wfvVal,
            plays:this.playVal,
            taps:this.tapVal,
            link:this.link,
            retweet:this.retweetVal,
            expands:this.expandsVal
          }, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 201){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Tersimpan'
                this.success = true
                this.loadCampaign()
                this.getContents()
                this.getInfluencers()
                // this.upload = ''
                this.loadingSave = false;
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      submitEdit(){
        this.dialog = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        axios.patch(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/campaignDetail/'+this.dialogItem.id, {
            campaign_id: this.itemId,
            influencer_id: this.influName,
            content: this.content,
            upload_date: this.upload,
            reach: this.reachVal,
            impressions: this.impresVal,
            views: this.viewsVal,
            clicks: this.clicksVal,
            likes: this.likesVal,
            comments: this.commentsVal,
            region: this.region,
            shares:this.shareVal,
            saved : this.savedVal,
            reply : this.replyVal,
            account_engaged : this.accountEngagedVal,
            avd:this.avdVal,
            wfv:this.wfvVal,
            plays:this.playVal,
            taps:this.tapVal,
            link:this.link,
            retweet:this.retweetVal,
            expands:this.expandsVal
          }, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Tersimpan'
                this.success = true
                this.loadCampaign()
                this.getContents()
                this.getInfluencers()
                this.loadingSave = false;
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      deleteItem(){
        this.dialogDelete = false;
        this.loadingSave = true;
        let token = localStorage.getItem('token')
        axios.delete(
          process.env.VUE_APP_API_ENDPOINT + 'campaign/campaignDetail/'+this.idDelete, {headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            if(response.status === 200){
              setTimeout(() => {
                this.dialogMessage = 'Berhasil Terhapus'
                this.success = true
                this.loadCampaign()
                this.loadingSave = false;
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      },
      checkCamp(start, end){
        let startYear = start.toString().slice(11, 16)
        let startMonth = start.toString().slice(4, 7)
        let startDay = start.toString().slice(8, 10)
        let endYear = end.toString().slice(11, 16)
        let endMonth = end.toString().slice(4, 7)
        let endDay = end.toString().slice(8, 10)
        let date;
        if(startYear === endYear && startMonth === endMonth && startDay === endDay){
          date = startDay+' '+startMonth+' '+startYear
        } else  if(startYear === endYear && startMonth === endMonth && startDay != endDay){
          date = startDay+' - '+endDay+' '+startMonth+' '+startYear
        } else  if(startYear === endYear && startMonth != endMonth){
          date = startDay+' '+startMonth+' - '+endDay+' '+endMonth+' '+startYear
        } else if(startYear != endYear){
          date = startDay+' '+startMonth+' '+startYear+' - '+endDay+' '+startMonth+' '+endYear
        }
        return date
      },
      refresh(){
        this.$router.go(0);
      },
      openDialog(type, id){
        this.influName = ''
        // this.content = ''
        // this.upload = ''
        this.reachVal = ''
        this.impresVal = ''
        this.viewsVal = ''
        this.clicksVal = ''
        this.likesVal = ''
        this.commentsVal = ''
        this.shareVal = ''
        this.savedVal = ''
        this.region = ''
        this.influNameManual = ''
        this.followerVal = ''
        this.replyVal = ''
        this.accountEngagedVal = ''
        this.link = ''
        this.retweetVal = ''
        this.expandsVal = ''
        this.switchInflu = false
        if(type === 'insert'){
          this.dialogItem.name = 'Tambah Influencer'
          this.dialog = true
          this.advanced = false
          // this.content = this.contentMaster;
          // this.upload = this.uploadMaster
        } else if (type === 'delete'){
          this.dialogDelete = true
          this.idDelete = id
        } else {
          this.advanced = true
          // this.twitter = true
          if (/twitter/i.test(this.content)) {
            this.contentType = 'twitter'
          } else if (/story/i.test(this.content)) {
            this.contentType = 'story'
          } else if (/carousel/i.test(this.content)) {
            this.contentType = 'carousel'
          } else if (/reels/i.test(this.content)) {
            this.contentType = 'reels'
          } else if (/tiktok/i.test(this.content)) {
            this.contentType = 'tiktok'
          }
          this.loadCampaignItem(id)
          this.dialogItem.id = id
          this.dialogItem.name = 'Ubah Influencer'
          this.dialog = true
        }
      },
      closeAlert(){
        this.alert = false
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      numberFormat (value) {
        return Intl.NumberFormat().format(value).replace(/,/g,'.');
      },
      dateLimit(value){
        return value.substring(0,10)
      },
      openLink(link){
        window.open(link, '_blank');
      },
      //this.$router.push({name:'campaign-payment'});
    },
    filters: {
      numberFormat (value) {
        return Intl.NumberFormat().format(value).replace(/,/g,'.');
      },
      labelFormat (value) {
        return value.replace(/-/g,' ');
      },
      dateLimit(value){
        return value.substring(0,10)
      }
    },
  })
  </script>

  <style scoped>
      .centered-input >>> input {
        text-align: center;
      }
  </style>
